
import { PAGES } from "@/common/consts";

/**
 * @TODO 	This code has been migrated from the Vue site as it is, as the scope of the ticket is too narrow
 * 			and making any refactor on in right now its not possible. However, we may want to make this
 * 			a bit more less messy, as right now can get a bit confusing. For now its fine as this works on its own
 * 			and can be placed anywhere where the routes below apply and wont have any significant impact
 * 			on the rest of the site.
 */

export default {
	name: "RelatedLinks",
	computed: {
		links() {
			/**
			 * @note to avoid the WFW-5565 PR from becoming waaaaay too big,
			 * I'm not refactoring this to use the new `getSearchParamsFromRoute`
			 * method just yet.
			 * Marking it as a @todo for now.
			 */
			switch (this.$route.name) {
				case PAGES.UNSCRAMBLER_RESULTS:
					return this.getDefaultLinks("Unscramble");
				case PAGES.SCRABBLE_DICT_RESULTS:
					return this.getDefaultLinks("ScrabbleDictionary");
				case PAGES.WORDS_HAVING_INDEX:
				case PAGES.WORDS_STARTING_INDEX:
				case PAGES.WORDS_ENDING_INDEX:
				case PAGES.WORDS_BY_LENGTH_INDEX:
				case PAGES.WORDS_VOWELS:
				case PAGES.WORDS_CONSONANTS:
				case PAGES.WORDS_WITH_Q_NO_U:
				case PAGES.WORDS_ENDING_ING:
					return this.getWordListIndexPageLinks(this.$route.path);
				case PAGES.WORDS_HAVING_RESULTS:
				case PAGES.WORDS_STARTING_RESULTS:
				case PAGES.WORDS_ENDING_RESULTS:
				case PAGES.WORDS_COMBINATION_LETTERS_RESULTS:
					return this.getWordListDetailLetterPageLinks(
						this.$route.name,
						this.$route.params.letter || this.$route.params.advanced
					);
				case PAGES.WORDS_BY_LENGTH_RESULTS:
				case PAGES.WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS:
					return this.getWordListDetailNumberPageLinks(
						this.$route.name,
						this.$route.params.length || this.$route.params.advanced
					);
				case PAGES.ARTICLE_INDEX:
				case PAGES.ARTICLE_DETAIL:
				case PAGES.WORDLE_ARTICLES_INDEX:
				case PAGES.WORDLE_ARTICLE_DETAIL:
				case PAGES.GAME_CHEATS:
				case PAGES.WORDS_LISTS_INDEX:
					return this.getWordListIndexPageLinks();
				default:
					return [];
			}
		},
	},
	methods: {
		getDefaultLinks(prefix) {
			const links = [];

			[1, 2].forEach((i) => {
				links.push({
					text: this.$t(
						`${prefix}ResultsInternalLinksText${i}GamePage`
					),
					title: this.$t(
						`${prefix}ResultsInternalLinksTitleAttribute${i}GamePage`
					),
					url: this.$t(
						`${prefix}ResultsInternalLinksUrl${i}GamePage`
					),
				});
			});

			for (let i = 1; i <= 8; i++) {
				links.push({
					text: this.$t(
						`${prefix}ResultsInternalLinksText${i}WordList`
					),
					title: this.$t(
						`${prefix}ResultsInternalLinksTitleAttribute${i}WordList`
					),
					url: this.$t(
						`${prefix}ResultsInternalLinksUrl${i}WordList`
					),
				});
			}

			links.push({
				text: this.$t(`${prefix}ResultsInternalLinksTextBlog`),
				title: this.$t(
					`${prefix}ResultsInternalLinksTitleAttributeBlog`
				),
				url: this.$t(`${prefix}ResultsInternalLinksUrlBlog`),
			});

			return links;
		},
		getWordListIndexPageLinks(excludePath = "") {
			const links = [];

			for (let i = 1; i <= 8; i++) {
				// Check for the matching URL path and exclude it.
				const url = this.$t(
					`WordListsIndexPageInternalLinksUrl${i}WordList`
				);
				if (typeof excludePath === "string" && excludePath.length) {
					if (url === excludePath) {
						continue;
					}
				}

				links.push({
					text: this.$t(
						`WordListsIndexPageInternalLinksText${i}WordList`
					),
					title: this.$t(
						`WordListsIndexPageInternalLinksTitleAttribute${i}WordList`
					),
					url,
				});
			}

			[1, 2].forEach((i) => {
				links.push({
					text: this.$t(
						`WordListsIndexPageInternalLinksText${i}GamePage`
					),
					title: this.$t(
						`WordListsIndexPageInternalLinksTitleAttribute${i}GamePage`
					),
					url: this.$t(
						`WordListsIndexPageInternalLinksUrl${i}GamePage`
					),
				});
			});

			links.push({
				text: this.$t(`WordListsIndexPageInternalLinksTextBlog`),
				title: this.$t(
					`WordListsIndexPageInternalLinksTitleAttributeBlog`
				),
				url: this.$t(`WordListsIndexPageInternalLinksUrlBlog`),
			});

			return links;
		},
		getWordListDetailPageIndexedLinks(count = 8) {
			const links = [];

			for (let i = 1; i <= count; i++) {
				links.push({
					text: this.$t(
						`WordListsDetailPagesInternalLinksText${i}WordList`
					),
					title: this.$t(
						`WordListsDetailPagesInternalLinksTitleAttribute${i}WordList`
					),
					url: this.$t(
						`WordListsDetailPagesInternalLinksUrl${i}WordList`
					),
				});
			}

			return links;
		},
		getLetterLink(type, letter) {
			let page = PAGES.WORDS_HAVING_RESULTS;
			let text = `Words with the Letter ${letter.toUpperCase()}`;

			if (type === "starting") {
				page = PAGES.WORDS_STARTING_RESULTS;
				text = `Words that start with ${letter.toUpperCase()}`;
			} else if (type === "ending") {
				page = PAGES.WORDS_ENDING_RESULTS;
				text = `Words Ending in ${letter.toUpperCase()}`;
			}

			const letterLink = this.$router.resolve({
				name: page,
				params: { letter },
			});

			let url = letterLink.href;
			if (url.substring(url.length - 1) !== "/") {
				url += "/";
			}

			return {
				text,
				title: `${text} List`,
				url,
			};
		},
		getNumberLink(number) {
			const numberQuery = Number(number);

			const lengthLink = this.$router.resolve({
				name: PAGES.WORDS_BY_LENGTH_RESULTS,
				params: { length: numberQuery },
			});

			let url = lengthLink.href;
			if (url.substring(url.length - 1) !== "/") {
				url += "/";
			}

			return {
				text: this.$t("WordsByNumberPageH1", {
					numberQuery,
				}),
				title: `${this.$t("WordsByNumberPageH1", {
					numberQuery,
				})} List`,
				url,
			};
		},
		getWordListDetailLetterPageLinks(routeName, letter) {
			const links = [];

			if (letter.length === 1) {
				// If it's only one letter, then simply pick the 2 alternative types for the same letter.
				// E.g. if this is a starting-with-X page, then pick contains-X and ending-with-X.
				let altTypes = ["starting", "ending"];
				if (routeName === PAGES.WORDS_ENDING_RESULTS) {
					altTypes = ["starting", "contains"];
				} else if (routeName === PAGES.WORDS_STARTING_RESULTS) {
					altTypes = ["contains", "ending"];
				}

				altTypes.forEach((type) => {
					links.push(this.getLetterLink(type, letter));
				});
			} else if (routeName === PAGES.WORDS_HAVING_RESULTS) {
				let firstLetter;
				let secondLetter;
				if (/^[a-zA-Z]-and-[a-zA-Z]$/.test(letter)) {
					[, firstLetter, secondLetter] = letter.match(
						/^([a-zA-Z])-and-([a-zA-Z])$/
					);
				} else if (/^[a-zA-Z]{2}/.test(letter)) {
					firstLetter = letter[0];
					secondLetter = letter[1];
				}

				if (firstLetter && secondLetter) {
					[firstLetter, secondLetter].forEach((l) => {
						links.push(this.getLetterLink("contains", l));
					});
				}
			} else {
				// This is either starting with or ending with
				let firstLetter;
				let secondLetter;
				if (/^[a-zA-Z]{2}/.test(letter)) {
					firstLetter = letter[0];
					secondLetter = letter[1];
				}

				if (firstLetter && secondLetter) {
					[firstLetter, secondLetter].forEach((l) => {
						links.push(this.getLetterLink("starting", l));
					});
				}
			}

			return routeName === PAGES.WORDS_COMBINATION_LETTERS_RESULTS
				? this.getWordListDetailPageIndexedLinks()
				: links.concat(this.getWordListDetailPageIndexedLinks());
		},
		getWordListDetailNumberPageLinks(routeName, number) {
			let links = [];

			if (/^\d+-with-[a-zA-Z]+$/.test(number)) {
				// If it's a number-and-letter URL (e.g. /letter-words/3-and-q/), then the 2 URLs should be a length detail page of the number, and a words-with detail page of the letter.
				const [, n, letter] = number.match(/^(\d+)-with-([a-zA-Z]+)$/);

				links.push(this.getNumberLink(n));
				links.push(this.getLetterLink("contains", letter));
			} else if (/^\d+$/.test(number)) {
				// If it's just a number (e.g. /letter-words/10) then pick adjacent numbers where possible, and follow specific scenarios otherwise.
				let relatedNumbers = [7, 8];
				const n = Number(number);

				if (n <= 2) {
					relatedNumbers = [3, 4];
				} else if (n >= 3 && n <= 11) {
					relatedNumbers = [n - 1, n + 1];
				} else if (n === 12) {
					relatedNumbers = [10, 11];
				}

				relatedNumbers.forEach((n) =>
					links.push(this.getNumberLink(n))
				);
			}

			links = links.concat(this.getWordListDetailPageIndexedLinks());

			return links;
		},
	},
};
