
export default {
	name: "TheSidebar",
	props: {
		isSticky: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		offsetTop() {
			if (!this.isSticky) {
				return null;
			}
			const clearanceHeight =
				this.$store.getters["window/headerClearanceHeight"];
			return `${clearanceHeight + 15}px`;
		},
	},
};
