import { render, staticRenderFns } from "./TheArticlesPromo.vue?vue&type=template&id=3ba3be23&scoped=true&"
import script from "./TheArticlesPromo.js?vue&type=script&lang=js&"
export * from "./TheArticlesPromo.js?vue&type=script&lang=js&"
import style0 from "./TheArticlesPromo.scss?vue&type=style&index=0&id=3ba3be23&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba3be23",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyImage: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/LazyImage/LazyImage.vue').default})
