
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

import { PRODUCTION_ORIGIN } from "@consts";

dayjs.extend(utc);
dayjs.extend(tz);

export default {
	name: "HomeSnippet",
	props: {
		updatedAt: {
			type: Number,
			required: true,
		},
	},
	head() {
		return {
			script: [
				{
					hid: "jsonld-app-googleplay",
					type: "application/ld+json",
					json: {
						"@context": "https://schema.org/",
						"@type": "AggregateRating",
						itemReviewed: {
							"@type": "SoftwareApplication",
							image: `${PRODUCTION_ORIGIN}/static/images/wf-app-icon-android.png`,
							name: "WordFinder for Android",
							applicationSubCategory: "Word",
							applicationCategory: "Game",
							downloadUrl: "https://play.google.com/store/apps/details?id=com.lovetoknow.wordfinder&pcampaignid=web_share",
							operatingSystem: "Android",
							softwareVersion: "7.1.1"
						},
						ratingValue: "4.6",
						ratingCount: 5620
					}
				},
			]
		}
	},
	computed: {
		formattedUpdateDate() {
			return dayjs(this.updatedAt || undefined).format("MM/DD/YYYY");
		}
	}
}
