var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"articles-promo"},[_c('div',{ref:"articlesPromoContent",staticClass:"content"},[_c('h2',{staticClass:"title"},[_c('router-link',{staticClass:"title__link",attrs:{"to":"/blog/"},nativeOn:{"click":function($event){return _vm.onArticlePromoElementClick('promoTitle')}}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("ArticlePromoModuleH2"))+"\n\t\t\t")])],1),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('img',{staticClass:"loading__image",attrs:{"src":"/static/images/ico-loading.svg","width":"40","height":"40","alt":"Loading..."}})]):_c('ul',{staticClass:"list"},_vm._l((_vm.articles),function(article,index){return _c('li',{key:index,staticClass:"article"},[_c('router-link',{staticClass:"article__photo",attrs:{"to":_vm._f("articleLink")(article.fullSlug),"title":article.title},nativeOn:{"click":function($event){return _vm.onArticlePromoElementClick(
							'promoArticleImage',
							index
						)}}},[_c('LazyImage',{staticClass:"article__img",attrs:{"source":article.coverImage.urlSmall,"alt":article.coverImage.description,"width":article.coverImage.width,"height":article.coverImage.height}})],1),_vm._v(" "),_c('router-link',{staticClass:"article__text",attrs:{"to":_vm._f("articleLink")(article.fullSlug),"title":article.title},nativeOn:{"click":function($event){return _vm.onArticlePromoElementClick(
							'promoArticleTitle',
							index
						)}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(article.title)+"\n\t\t\t\t")]),_vm._v(" "),_c('span',{staticClass:"article__hint"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm._f("date")(article.updatedAt))+" •\n\t\t\t\t\t"+_vm._s(article.minutesToRead)+"\n\t\t\t\t\t"+_vm._s(_vm.$t("ArticlesIndexPageReadingTime"))+"\n\t\t\t\t")])],1)}),0),_vm._v(" "),_c('div',[_c('router-link',{staticClass:"footer-button",attrs:{"to":"/blog/","title":_vm.$t('ArticlePromoModuleSeeAllTitleAttribute')},nativeOn:{"click":function($event){return _vm.onArticlePromoElementClick('promoArticleButton')}}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("ArticlePromoModuleSeeAllButton"))+"\n\t\t\t")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }