import { isEmpty } from "@utils";
import { getArticles } from "@/common/services/articleService";

const articlePromoGaEventsCollection = {
	promoTitle: [
		{
			action: "Click article promo module title",
			id: 34,
		},
	],
	promoArticleTitle: [
		{
			action: "Click first article title",
			id: 33,
		},
		{
			action: "Click second article title",
			id: 30,
		},
	],
	promoArticleImage: [
		{
			action: "Click first article photo",
			id: 32,
		},
		{
			action: "Click second article photo",
			id: 29,
		},
	],
	promoArticleButton: [
		{
			action: "Click see all articles button",
			id: 31,
		},
	],
};

export default {
	name: "TheArticlesPromo",
	async fetch() {
		this.isLoading = true;
		const response = await getArticles(this.$axios, { limit: 2 });

		this.isLoading = false;
		if (isEmpty(response)) {
			this.isError = true;
		} else {
			this.articles = response.data;
		}
	},
	data() {
		return {
			articles: [],
			isLoading: false,
			isError: false,
		};
	},
	filters: {
		date(date) {
			return new Date(date).toLocaleDateString("en", {
				month: "short",
				day: "numeric",
				year: "numeric",
			});
		},
		articleLink(fullSlug) {
			return `/blog/${fullSlug.substr(5)}/`;
		},
	},
	computed: {
		articlePromoGaEvents() {
			return articlePromoGaEventsCollection;
		},
	},
	methods: {
		onArticlePromoElementClick(type, index = 0) {
			this.$store.dispatch("tracking/trackEvent", {
				eventCategory: "Article Promo Module",
				eventAction: this.articlePromoGaEvents[type][index].action,
				eventID: this.articlePromoGaEvents[type][index].id,
			});
		},
	},
};
