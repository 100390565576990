
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { mapGetters } from "vuex";
import {
	AD_LAYOUT_STUBS,
	AD_PLACEMENTS,
	PAGES,
	PRODUCTION_ORIGIN,
} from "@consts";
import { getCMSCopy } from "@/common/services/cmsCopyService";

dayjs.extend(utc);
dayjs.extend(tz);

export default {
	name: "GamesLanding",

	data() {
		return {
			pageTitle: "",
			pageSubtitle: "",
			pageContent: "",
			pageMetaTitle: "",
			pageMetaDescription: "",
			contentUpdatedAt: null,
			AD_LAYOUT_STUBS,
			AD_PLACEMENTS,
		};
	},
	async fetch() {
		const cmsCopyKey = this.getCmsCopyKey();
		const cmsCopy = await getCMSCopy(
			this.$axios,
			this.$config,
			cmsCopyKey,
			process.env.version
		);

		this.contentUpdatedAt = cmsCopy.data.updatedAt;
		this.pageTitle = cmsCopy.data.title;
		this.pageSubtitle = cmsCopy.data.teaser;
		this.pageContent = cmsCopy.data.body;
		this.pageMetaTitle = cmsCopy.data.metaTitle;
		this.pageMetaDescription = cmsCopy.data.metaDescription;

		this.$store.commit(
			"navigation/setBreadcrumbsOverride",
			this.mergeBreadcrumbs()
		);
	},
	head({ $buildMetaDataObject }) {
		const metaData = $buildMetaDataObject({
			title: this.pageMetaTitle,
			description: this.pageMetaDescription,
		});

		if (this.isHomePage) {
			metaData.script = [
				{
					hid: "jsonld-organization",
					json: null,
				},
				{
					hid: "jsonld-homepage",
					type: "application/ld+json",
					json: {
						"@context": "https://schema.org",
						"@type": "Article",
						headline: "WordFinder by YourDictionary",
						url: PRODUCTION_ORIGIN,
						author: {
							"@type": "Person",
							name: "Claudia Boone",
							url: `${PRODUCTION_ORIGIN}/about/`,
						},
						dateModified: dayjs(this.contentUpdatedAt || undefined)
							.utc()
							.toISOString(),
						datePublished: dayjs("2012-08-17").utc().toISOString(),
						description:
							"Scrabble Word Finder from YourDictionary is the perfect word finder for playing the new word game Scrabble GO. Score more points and win more often with our free Scrabble cheat tool.",
						image: "https://storage.googleapis.com/ltkcms.appspot.com/fs/wfa/images/cover/landing-page-homepage-how-use.base.webp?v=1664900677",
						about: {
							"@type": "Organization",
							name: "WordFinder by YourDictionary",
							url: PRODUCTION_ORIGIN,
							logo: `${PRODUCTION_ORIGIN}/static/images/ic_wfw_complete.png`,
							address: {
								"@type": "PostalAddress",
								addressLocality: "Vero Beach, FL",
								postalCode: "32960-4706",
								streetAddress: "1275 US Highway 1 Unit 2-6084",
							},
							email: "wordfinder@yourdictionary.com",
							sameAs: [
								"https://www.facebook.com/WordFinderbyYD/",
								"https://x.com/wordfinderbyyd",
								"https://www.instagram.com/wordfinderbyyd/",
							],
							description:
								"Scrabble Word Finder from YourDictionary is the perfect word finder for playing the new word game Scrabble GO. Score more points and win more often with our free Scrabble cheat tool.",
							parentOrganization: {
								"@type": "Organization",
								"@id": "https://www.lovetoknowmedia.com/",
								name: "LoveToKnow Media",
								url: "https://www.lovetoknowmedia.com/",
								sameAs: [
									"https://www.linkedin.com/company/lovetoknowmedia/",
									"https://www.instagram.com/lovetoknowmedia/",
									"https://www.facebook.com/lovetoknowmedia/about",
									"https://x.com/lovetoknowmedia",
									"https://www.crunchbase.com/organization/lovetoknow-corp",
								],
								logo: {
									"@type": "ImageObject",
									url: "https://www.lovetoknowmedia.com/wp-content/uploads/2021/05/LTK_MEDIA_Logo-2.png",
								},
								address: {
									"@type": "PostalAddress",
									addressLocality: "Vero Beach, FL",
									postalCode: "32960-4706",
									streetAddress:
										"1275 US Highway 1 Unit 2-6084",
								},
							},
						},
						isPartOf: {
							"@context": "https://schema.org",
							"@type": "WebSite",
							name: "WordFinder by YourDictionary",
							url: PRODUCTION_ORIGIN,
							potentialAction: {
								"@type": "SearchAction",
								target: {
									"@type": "EntryPoint",
									urlTemplate: `${PRODUCTION_ORIGIN}/unscramble/{search_term_string}/`,
								},
								"query-input":
									"required name=search_term_string",
							},
						},
					},
				},
			];
		}

		return metaData;
	},
	computed: {
		...mapGetters("navigation", [
			"isWordListDistributorPage",
			"isWordlistIndexPage",
			"isWordleLandingPage",
			"isQuordleLandingPage",
			"isHomePage",
		]),
		hasBreadcrumbs() {
			return !this.isHomePage;
		},
	},
	methods: {
		getCmsCopyKey() {
			const prefix = ".seo.landing.";

			switch (this.$route.name) {
				case PAGES.HOME:
					return `${prefix}home`;
				case PAGES.WORDS_LISTS_INDEX:
					return `${prefix}word-lists`;
				case PAGES.WORDS_STARTING_INDEX:
					return `${prefix}words-that-start-with`;
				case PAGES.WORDS_HAVING_INDEX:
					return `${prefix}words-containing`;
				case PAGES.WORDS_ENDING_INDEX:
					return `${prefix}words-ending-in`;
				case PAGES.WORDS_BY_LENGTH_INDEX:
					return `${prefix}words-by-length-landing`;
				case PAGES.WORD_WARS:
					return `${prefix}word-wars-cheat`;
				default:
					return `${prefix}${this.$route.path.replace(/\//g, "")}`;
			}
		},
		mergeBreadcrumbs() {
			if (this.isHomePage) {
				return [];
			}
			const staticBreadcrumbs = this.$route.meta?.breadcrumbs || [];
			staticBreadcrumbs.pop();
			staticBreadcrumbs.push({
				label: this.pageTitle,
				url: "",
			});
			return staticBreadcrumbs;
		},
	},
};
