import { render, staticRenderFns } from "./GamesLanding.vue?vue&type=template&id=7bebcf98&scoped=true&"
import script from "./GamesLanding.vue?vue&type=script&lang=js&"
export * from "./GamesLanding.vue?vue&type=script&lang=js&"
import style0 from "./GamesLanding.vue?vue&type=style&index=0&id=7bebcf98&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bebcf98",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Finder: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/finder/Finder/Finder.vue').default,Advertisement: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/Advertisement/Advertisement.vue').default,RelatedLinks: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/RelatedLinks/RelatedLinks.vue').default,TheArticlesPromo: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheArticlesPromo/TheArticlesPromo.vue').default,TheSidebar: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/template/TheSidebar.vue').default,HomeSnippet: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/HomeSnippet/HomeSnippet.vue').default,WordListInterlinkDirector: require('/home/runner/work/WordFinder-V3/WordFinder-V3/components/results/WordListInterlinkDirector/WordListInterlinkDirector.vue').default})
