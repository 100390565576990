/**
 * Gets the CMS copy, given a page slug. Cache can be invalidated by passing a different version value
 *
 * @param {import('@nuxtjs/axios').NuxtAxiosInstance} axios Axios instance
 * @param config The runtime config
 * @param {string} contentKey The content key associated with the CMS article
 * @param {string} version Cache key used to invalidate the cache
 */
export const getCMSCopy = async (axios, config, contentKey, version) =>
	await axios
		.get(
			`${config.API_SERVICE_CMS_V2}/wfa/article/${contentKey}?v=${version}`
		)
		.then((response) =>
			response.status === 200 && response.data.status === 200
				? response.data
				: {}
		)
		.catch((err) => {
			console.error(
				`Could not get CMS copy for ${contentKey} ${version} `,
				err
			);
			throw err;
		});
