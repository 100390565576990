export default {
	name: "ImageItem",
	props: {
		source: {
			type: String,
			required: true,
		},
		alt: {
			type: String,
			default: "image",
		},
		width: {
			type: Number,
			required: true,
		},
		height: {
			type: Number,
			required: true,
		},
	},
};
